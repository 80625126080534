<template>
  <div>
    <div id="compoundPop" v-show="compoundPopShow">
      <img
        class="closeIcon"
        style="pointer-events: auto"
        @click="$emit('close')"
        src="../imgs/compound/close.png"
      />
      <div>
        <img
          class="title"
          src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656332787669.jpg"
        />
      </div>

      <div class="compound">
        <div :class="flag ? 'trunActive' : ''">
          <img class="trun" src="../imgs/compound/trunAround.png" alt="" srcset="" />
          <img
            class="trun trunIcon"
            :class="open ? 'reduceActive' : ''"
            :src="effect_img"
            alt=""
            srcset=""
          />
        </div>

        <div class="flyGif">
          <img class="trun" src="../imgs/compound/fly.gif" alt="" srcset="" />
        </div>

        <div class="card" :class="open ? 'cardActive' : ''">
          <img src="../imgs/compound/card.png" alt="" srcset="" />
        </div>
      </div>

      <div class="btnBox" @click="compoundHandle">
        <img src="../imgs/compound/btnBg.png" />
        <div class="confirmBtn">确定合成</div>
      </div>
    </div>

    <van-popup class="vantPop" v-model:show="show" @close="$emit('close')">
      <div class="successImg">
        <img
          src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656397513596.jpg"
        />
      </div>
      <div class="bigCard" :class="show ? 'bigCardActive' : ''">
        <img
          class="bg"
          src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656408436320.jpg"
        />

        <!-- <div class="num">编号：{{ cardInfo.token_id + '-' + cardInfo.serial_num }}</div> -->

        <div class="cardPicBox">
          <div class="cardPic">
            <img class="pic" :src="cardInfo.image_icon" alt="" srcset="" />
            <img class="levelIcon" :src="levelsChange(cardInfo.levels)" alt="" />
          </div>
        </div>
      </div>
      <div class="btnBox" @click="toCollections">
        <img src="../imgs/compound/btnBg.png" />
        <div class="confirmBtn">查看藏品</div>
      </div>
    </van-popup>

    <!-- <div class="zh-circle">
      <img
        :style="{ transform: 'rotate(' + (360 / 3) * 0 + 'deg)' }"
        src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656310507599.jpg"
        alt=""
        srcset=""
      />
      <img
        :style="{ transform: 'rotate(' + (360 / 3) * 1 + 'deg)' }"
        src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656310507599.jpg"
        alt=""
        srcset=""
      />
      <img
        :style="{ transform: 'rotate(' + (360 / 3) * 2 + 'deg)' }"
        src="https://activity-resource.321mh.com/nft/activity_cartoon_competition/activity_images1656310507599.jpg"
        alt=""
        srcset=""
      />
    </div> -->
  </div>
</template>

<script>
import { reactive, toRefs, inject } from 'vue';
import { mergeitems } from '@/service/compound';
import { useRouter } from 'vue-router';

export default {
  props: {
    compoundPopShow: {
      type: Boolean,
      default: false
    },
    config_id: {
      type: Number,
      default: 0
    },
    token_id: {
      type: Number,
      default: 0
    },
    effect_img: {
      type: String,
      default: '../imgs/compound/trunIcon.png'
    }
  },

  setup(props, ctx) {
    const router = useRouter();
    const toastFn = inject('$toast');
    const state = reactive({
      step: 1,
      show: false,
      flag: false, //开始合成（请求接口，开始加载第一个动画trunActive）
      open: false, //请求接口获取合成的图片过程（加载第二个动画）reduceActive/cardActive
      popShow: true,

      cardInfo: {}
    });

    // 确认合成
    const compoundHandle = () => {
      state.flag = true;

      setTimeout(() => {
        mergeitems({
          config_id: props.config_id,
          token_id: props.token_id
        }).then(res => {
          console.log(res);
          if (res.status == 0) {
            state.open = true;
            state.show = true;

            state.cardInfo = res.data;

            setTimeout(() => {
              console.log(props);
              state.popShow = false;
              ctx.emit('close');
              state.open = false;
              // state.show = false;
              state.flag = false;
            }, 1000);
          } else {
            ctx.emit('close');
            toastFn({ message: res.message, duration: 3000 });
          }
        });
      }, 1000);

      // setTimeout(() => {

      // }, 2000);

      // setTimeout(() => {
      //   state.step = 2;
      //   setTimeout(() => {
      //     state.show = true;
      //     state.step = 1;
      //     state.flag = false;
      //   }, 550);
      // }, 2000);
    };

    // 查看藏品
    const toCollections = () => {
      router.push({ path: '/collections' });
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      compoundHandle,
      levelsChange,
      toCollections
    };
  }
};
</script>

<style lang="scss" scoped>
// .zh-circle {
//   position: relative;
//   width: 240px;
//   height: 240px;
//   border-radius: 50%;
//   border: 1px solid #f00;
//   box-sizing: border-box;
//   margin: 100px auto 0;
// }
// .zh-circle img {
//   position: absolute;
//   z-index: 2;
//   left: 50%;
//   top: 0;
//   width: 40px;
//   height: 40px;
//   margin: -20px 0 0 -20px;
//   border: 1px solid #f00;
//   border-radius: 50%;
//   box-sizing: border-box;
//   list-style: none;
//   transform-origin: 20px 140px;
// }
#compoundPop {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #202232;
  // height: 100%;
  text-align: center;
}
.closeIcon {
  position: absolute;
  z-index: 5000;
  width: 16px;
  height: 16px;
  padding: 20px;
  right: 0;
  top: 0;
}
.title {
  // width: 100%;
  width: 269px;
  margin-top: 30px;
  margin-bottom: 25px;
}

.compound {
  width: 100%;
  position: relative;
  // height: 388px;

  .flyGif {
    width: 100%;
    // height: 388px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .card {
    width: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      width: 100%;
    }
  }

  .trunAround {
    position: absolute;
    left: 0;
    top: 0;
  }

  .trun {
    width: 100%;
  }

  .trunIcon {
    position: absolute;
    left: 0;
    top: 0;
  }

  .trunActive {
    animation: trun 2s linear infinite;
  }

  .reduceActive {
    animation: reduce 1s linear 1;
  }

  .cardActive {
    animation: big 1s linear 1;
  }
}

.btnBox {
  width: 164px;
  font-size: 0;
  margin: 0 auto;
  margin-top: 15px;
  position: relative;

  img {
    width: 100%;
  }

  .confirmBtn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    white-space: nowrap;
    font-size: 20px;
    font-weight: 600;
    color: #20322c;
  }
}

.successImg {
  width: 100%;
  margin-bottom: 10px;
  img {
    width: 100%;
  }
}

.bigCard {
  text-align: center;
  width: 292px;
  position: relative;
  .bg {
    width: 100%;
  }

  .num {
    position: absolute;
    width: 100%;
    top: 13px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
  }

  .cardPicBox {
    position: absolute;
    width: 234px;
    top: 76px;
    left: 50%;
    transform: translateX(-50%);

    .cardPic {
      position: relative;

      .pic {
        width: 100%;
      }

      .levelIcon {
        position: absolute;
        width: 60px;
        bottom: 21px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}

.bigCardActive {
  animation: bigCardAnimate 0.5s linear 1;
}

@keyframes trun {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes reduce {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.2);
  }
}

@keyframes big {
  from {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  to {
    transform: translate(-50%, -50%) scale(2.5);
    opacity: 0;
  }
}

@keyframes bigCardAnimate {
  from {
    opacity: 0;
    transform: scale(0.3);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
</style>
