<template>
  <div class="container">
    <div class="head-bg">
      <!-- <van-image width="100vw" :src="img1" /> -->
      <div class="ruls" @click="showrule = true">规则</div>
      <van-image width="100vw" :src="detail.merge_image_bg" />
    </div>
    <div class="meng"></div>
    <div class="content">
      <div class="content-item">
        <div class="title">
          <div class="name">
            {{ detail.merge_item_name }}
            <img :src="levelsChange(detail.merge_item_levels)" alt="" />
          </div>
          <div class="num">限量合成{{ detail.merge_count }}份</div>
        </div>
        <div class="cl-box">
          <div class="title"><img src="@/imgs/comm/cl1.png" alt="" /></div>
          <div class="hero">
            <div
              class="hero-item"
              v-for="(item, index) in detail.merge_config_items"
              :key="index"
              v-show="item.material_type == 1"
            >
              <div class="box1">
                <img :src="item.image_icon" alt="" />
                <div class="num">{{ item.user_num }}</div>
                <div class="item-meng" v-if="item.user_num < item.number"></div>
                <div class="neednum">
                  需<span>{{ item.number }}</span
                  >个
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="add">
          <van-icon name="plus" />
        </div>
        <div class="cl-box" style="margin-top: 0px">
          <div class="title"><img src="@/imgs/comm/cl2.png" alt="" /></div>
          <!-- style="justify-content: space-around; padding: 0 70px" -->
          <div class="hero-wrapper">
            <div class="hero">
              <div
                class="hero-item"
                :class="token_id == item.token_id ? 'choose' : 'nochoose'"
                v-for="(item, index) in detail.merge_config_items"
                :key="index"
                @click="tokenidChoose(item)"
                v-show="item.material_type == 2"
              >
                <div class="box">
                  <img :src="item.image_icon" alt="" />
                  <div class="num">{{ item.user_num }}</div>
                  <div class="item-meng" v-if="item.user_num < item.number"></div>
                  <div class="neednum">
                    需<span>{{ item.number }}</span
                    >个
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrapper">
          <!-- <div class="hc-btn">立即合成</div> -->
          <cpdBtn
            :time="detail.is_user_priority == 0 ? detail.start_time : detail.priority_start_time"
            :end_time="detail.end_time"
            :priority_start_time="detail.priority_start_time"
            :hasAll="detail.hasAll"
            @finistTime="finistTime"
            :is_merge="detail.is_merge"
            @compoundHreo="compoundHreo"
            :server_time="server_time"
            :merge_type="detail.merge_type"
          />
        </div>
        <!--  -->
        <!-- <div class="bt-words" v-if="diffTime(detail.start_time) > 0">
          拥有“<span>荒古大帝</span> ”，可在{{
            timeChange(detail.priority_start_time, 'MM月DD日HH:mm')
          }}-{{ timeChange(detail.start_time, 'HH:mm') }}优先合成
        </div> -->
      </div>
      <div class="content-item">
        <div class="sub-title">
          <div class="sub-title-box">
            <img src="@/imgs/comm/titleBg.png" alt="" />
            <div class="titleText">藏品权益</div>
          </div>
        </div>
        <div class="sub-content">
          <!-- <div class="">
            持有<span>「荒古大帝」</span>或<span>「东荒女帝」</span>藏品的用户将获得以下权益：
          </div> -->
          <div class="">
            <span>1.</span>
            享受看漫平台后续发售指定藏品的各类优先特权，包括但不限于优先购买、优先合成、优先中签等；
          </div>
          <div class=""><span>2.</span> 不定期享受平台售卖和活动奖品的无条件免费空投；</div>
          <div class=""><span>3.</span> 可参与闭关修炼获得积分；</div>
          <div class="">
            <span>4.</span>
            创世俱乐部官方社群入群资格，可享受7*24小时尊享服务；
          </div>
          <div class="">
            <span>5.</span> 可获得看漫App至尊会员卡（价值2333元），享受平台会员漫画永久免费读权益；
          </div>
          <div class="">
            <span>6.</span> 可免费获得官方不定期发放的精美手办等实物，享有各类线下活动参与资格；
          </div>
          <div class="">
            <span>7.</span> 每同时持有1份「荒古大帝」和1份「东荒女帝」
            ，将免费空投1份即将上线的神话级藏品「叶凡」，权益可叠加。
          </div>
          <div class="">*更多权益即将解锁，敬请期待！</div>
        </div>
      </div>
      <div class="content-item">
        <div class="sub-title">
          <img src="@/imgs/comm/Slice38.png" alt="" />
        </div>
        <div class="sub-content t-c">
          <!-- {{ detail.merge_item_description }} -->
          <p>执子之手，与子偕老！</p>
          <p>
            曾经所有的美好悄然浮现在姬凝霜被鲜血染红的瞳孔之中，模糊了她的视线，凌乱了她的思绪。
          </p>
          <p>失去了你，便是渡劫证道又有何意义？</p>
          <p>你才是我心中的劫啊！</p>
          <p>既如此，那我便证个魔道，为你屠尽天下诸神，弑尽万界仙佛，还你一个清静之地。</p>
          <p>十大巅峰帝，滚来受死！</p>
        </div>
      </div>
      <div class="brigt">
        <div class="">
          <div class="t-c">
            <img class="wid237" :src="require('../imgs/comm/Slice15hc.png')" alt="" />
          </div>
          <div class="t-c f-10 brigt-content c-07">
            《仙武帝尊》第二期限量合成{{ detail.merge_count }}份
          </div>

          <div class="">
            <div class="flex-bt marg36">
              <img class="wid90" :src="require('../imgs/comm/Slice20.png')" alt="" />
              <img class="wid90" :src="require('../imgs/comm/Slice21.png')" alt="" />
            </div>
            <div class="flex-bt">
              <img class="wid90" :src="require('../imgs/comm/Slice22.png')" alt="" />
              <img class="wid90" :src="require('../imgs/comm/Slice23.png')" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="parameter f-12">
        <div class="">
          <div class="title t-c">
            <img class="wid237" :src="require('../imgs/comm/Slice16.png')" alt="" />
          </div>
          <div class="bright-item">
            <span class="c-07">认证标识</span>
            <span> 已上链</span>
          </div>
          <div class="bright-item">
            <span class="c-07">认证标准</span>
            <span> ERC1155</span>
          </div>
          <div class="bright-item">
            <span class="c-07">认证网络</span>
            <span> {{ detail.token_network }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">商品名称</span>
            <span> {{ detail.merge_sub_name }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">商品类型</span>
            <span> {{ detail.merge_items_type }}</span>
          </div>

          <div class="bright-item">
            <span class="c-07">品牌方</span>
            <span> {{ detail.merge_producter }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">发行方</span>
            <span> {{ detail.merge_publisher }}</span>
          </div>
          <div class="bright-item" v-if="detail.merge_publish_date">
            <span class="c-07">发行时间</span>
            <span> {{ timeChange(detail.merge_publish_date, 'YYYY-MM-DD') }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">商品编号</span>
            <span> {{ detail.merge_series_num }}</span>
          </div>
          <div class="bright-item">
            <span class="c-07">铸造数量</span>
            <span> {{ detail.token_num }}</span>
          </div>
        </div>
      </div>
    </div>
    <!-- 规则说明 -->
    <van-action-sheet v-model:show="showrule" :round="false" style="background-color: #1c172a">
      <div class="header">
        <img src="../imgs/comm/pic_tc_zs.png" class="img-zs" />
        <van-icon :name="icon_tc_gb" size="0.427rem" class="icon_tc_gb" @click="showrule = false" />
        <img src="../imgs/comm/pic_tc_title.png" class="img-title" />
        <span>合成须知</span>
      </div>
      <div class="block"></div>
      <div class="content-van">
        <div class="nei">
          <!-- <div class="nei-title">合成时间：</div>
          2022年10月28日16:00-2022年10月29日15:59<br />
          优先合成规则：每持有1份「东荒女帝优先合成特权」，可优先合成1份，权益可叠加；<br />优先合成时间：2022年10月28日15:00-15:59。<br /><br /> -->
          <div class="nei-title">合成材料：</div>
          集齐两种材料可合成「东荒女帝」：<br />
          • 材料1：<span
            >叶辰*1 ＋ 姬凝霜*1 ＋ 楚萱儿*2 ＋ 楚灵儿*2 ＋ 苏心月*3 ＋ 洛曦*4 ＋ 赤霄剑*10</span
          ><br />
          • 材料2： <span>气武境或灵武境*1（任选其一）</span><br /><br />
          <div class="nei-title">合成规则：</div>
          1、本期限量合成100份，先到先得；<br />
          2、优先合成规则：每持有1份「东荒女帝优先合成特权」，可优先合成1份，权益可叠加；优先合成时间：2022年10月28日15:00-15:59；<br />
          3、大众合成时间：2022年10月28日16:00-2022年10月29日15:59；<br />
          4、单人不限合成次数;<br />
          5、合成成功后，所需的材料将被销毁。
          <br />
          <br />
          <p class="t-c">最终解释权归看漫所有</p>
        </div>
      </div>
      <!-- <div class="block"></div> -->
    </van-action-sheet>
    <PageLoading :show="loading" />
    <compoundPop
      :compoundPopShow="compoundPopShow"
      :token_id="token_id"
      :config_id="detail.id"
      :effect_img="effect_img"
      @close="(compoundPopShow = false), finistTime()"
    />
  </div>
</template>

<script>
import { reactive, toRefs, onMounted } from 'vue';
import { getmergeitems, getmergeitemsv2 } from '@/service/home';
import cpdBtn from '@/components/CpdBtn';
import compoundPop from '@/components/compoundPop';
import moment from 'moment';
import PageLoading from '@/components/loading/PageLoading.vue';
import { useRoute } from 'vue-router';
export default {
  components: {
    cpdBtn,
    compoundPop,
    PageLoading
  },
  setup() {
    const route = useRoute();
    const state = reactive({
      showrule: false,
      detail: {},
      compoundPopShow: false,
      token_id: 0, // 选择的材料中的id
      effect_img: '',
      loading: true,
      server_time: 0,
      config_id: route.query.config_id || null
    });

    onMounted(async () => {
      finistTime();
    });
    const finistTime = async () => {
      // let getFn = state.config_id == 12 ? getmergeitemsv2 : getmergeitems;
      const { data, server_time } = await getmergeitemsv2({ config_id: state.config_id });
      state.detail = data;
      state.server_time = server_time;

      let merge_config_items = data.merge_config_items,
        user_merge_config_items = data.user_merge_config_items,
        arr = [],
        subitem,
        hasAll = true; // 材料是否充足;
      // 循环得出用户拥有数

      merge_config_items.map(item => {
        if (user_merge_config_items.length == 0) {
          hasAll = false;
          console.log(11111);
          arr.push({ ...item, user_num: 0 });
        } else {
          for (let i = 0; i < user_merge_config_items.length; i++) {
            subitem = user_merge_config_items[i];

            if (item.token_id == subitem.token_id) {
              arr.push({ ...item, user_num: subitem.number });
              // 判断材料2 选择第一个
              // 材料1不足
              if (subitem.number < item.number && item.material_type == 1) {
                hasAll = false;
                console.log(222);
              }
              if (item.material_type == 2 && subitem.number >= item.number && state.token_id == 0) {
                state.token_id = item.token_id;
                state.effect_img = item.effect_img;
              }

              break;
            }
            if (user_merge_config_items.length - 1 == i && item.token_id != subitem.token_id) {
              arr.push({ ...item, user_num: 0 });
              // 材料不足
              item.material_type == 1 && (hasAll = false);
              console.log(223332, item.token_id, subitem.token_id);
              break;
            }
          }
        }
      });
      // 材料2不足
      state.token_id == 0 && (hasAll = false);
      console.log(hasAll, state.token_id, arr, '....');
      state.detail.merge_config_items = arr;
      state.detail = { ...state.detail, hasAll };
      state.loading = false;
    };
    // 选择材料2
    const tokenidChoose = item => {
      //判断是否能够选择
      if (item.user_num >= item.number) {
        state.token_id = item.token_id;
        state.effect_img = item.effect_img;
      }
    };
    // 合成按钮
    const compoundHreo = () => {
      if (!state.token_id) {
        return;
      } // 预留位置做选择
      state.compoundPopShow = true;
    };
    // 计算与当前时间时间差
    const diffTime = time => {
      return moment(moment(time)).diff(moment(), 'millisecond');
    };

    const userNum = id => {
      return 1;
    };
    const timeChange = (time, formtype) => {
      return moment(time).format(formtype);
    };

    const levelsChange = level => {
      let src = '';
      //级别 1 普通 icon_label_ordinary，2 稀有 icon_label_rare，3 史诗 icon_label_epic，4 传说 icon_label_legend
      switch (level) {
        case 1:
          src = require('../imgs/comm/icon_label_ordinary.png');
          break;
        case 2:
          src = require('../imgs/comm/icon_label_rare.png');
          break;
        case 3:
          src = require('../imgs/comm/icon_label_epic.png');
          break;
        case 4:
          src = require('../imgs/comm/icon_label_legend.png');
          break;
        case 6:
          src = require('../imgs/comm/icon_label_cs.png');
          break;
        case 5:
          src = require('../imgs/comm/icon_label_sh.png');
          break;
      }
      return src;
    };

    return {
      ...toRefs(state),
      levelsChange,
      userNum,
      finistTime,
      compoundHreo,
      timeChange,

      tokenidChoose,
      diffTime,
      icon_tc_gb: require('../imgs/icon/icon_tc_gb.png')
    };
  }
};
</script>

<style lang="scss" scoped>
@import '../styles/mixin.scss';
.van-action-sheet {
  .header {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: fixed;
    background-color: $bc;
    padding-top: 3px;

    .img-zs {
      height: 16px;
    }
    .icon_tc_gb {
      margin-left: 300px;
      width: 16px;
      height: 16px;
    }
    .img-title {
      height: 16px;
    }
    span {
      font-size: 20px;
      color: $fc;
      margin-top: -20px;
    }
  }
  .block {
    height: 80px;
    background-color: $bc;
  }
  .content-van {
    background-color: $bc;
    color: $fc;
    font-size: 14px;

    .nei {
      margin-left: 16px;
      margin-right: 16px;

      color: rgba(255, 255, 255, 0.7);
      .nei-title {
        color: rgba(255, 255, 255, 1);
      }
      span {
        color: #3cdbe8;
      }
      p {
        margin: 0px;
        margin-bottom: 40px;
      }
    }
  }
}
.brigt-content {
  margin-bottom: 24px;
}
.head-bg {
  // position: relative;
  .ruls {
    position: absolute;
    left: 0;
    top: 220px;
    z-index: 9;
    width: 64px;
    height: 24px;
    background: #58dedd;
    font-size: 12px;
    color: #000000;
    border-radius: 0px 20px 20px 0px;
    display: flex;
    padding-left: 20px;
    box-sizing: border-box;
    font-weight: 500;
    // justify-content: center;
    align-items: center;
  }
}
.f-12 {
  font-size: 12px;
}
.parameter {
  @include bis('../imgs/comm/Slice9.png');
  padding: 10px 21px 27px;
  .title {
    margin-bottom: 19px;
    font-size: 0px;
  }
  .bright-item {
    @include fj();
    padding-bottom: 6px;
    margin-top: 10px;
    border-bottom: 1px solid #494359;
  }
}
.c-07 {
  color: rgba(255, 255, 255, 0.7);
}
.brigt {
  padding: 24px 54px 38px;
  @include bis('../imgs/comm/Slice9.png');
  margin-bottom: 24px;
}
.t-c {
  text-align: center;
}
.f-10 {
  font-size: 10px;
}
.f-0 {
  font-size: 0px;
}
.flex-bt {
  @include fj();
}
.wid237 {
  width: 237px;
}
.wid90 {
  width: 90px;
  height: 100px;
}
.marg36 {
  margin-bottom: 26px;
}
.bt-words {
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
  span {
    font-weight: 600;
  }
}
.btn-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 11px 0;
}
.hc-btn {
  background: url('../imgs/comm/hcbtn.png') no-repeat;
  background-size: 100% 100%;
  width: 164px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: 600;
  color: #20322c;
}
.meng {
  width: 100vw;
  height: 160px;
  position: relative;
  top: -160px;

  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #1c172a 75%);
}
.container {
  position: relative;
}
.content {
  padding: 0 16px 120px;
  position: absolute;
  top: 287px;
  max-width: 100vw;
  box-sizing: border-box;
  // margin-top: -80px;
}
.add {
  font-size: 24px;
  text-align: center;
  // margin: 4px 0;
}
.content-item2 {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .title {
    width: 100%;
    margin-top: 6px;
    img {
      width: 237px;
      margin: 0 auto;
    }
  }
}
.content-item {
  background: #2f293f;
  padding: 11px;
  margin-bottom: 33px;
  position: relative;
  .sub-title {
    position: absolute;
    top: -16px;
    left: 50%;
    transform: translate(-50%, 0);
    img {
      width: 193px;
    }
    .sub-title-box {
      font-size: 0px;
      position: relative;

      img {
        width: 192.5px;
        height: 36px;
      }

      .titleText {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        font-size: 16px;
        font-weight: 600;
        color: #ffffff;
        white-space: nowrap;
      }
    }
  }
  .sub-content {
    margin-top: 34px;
    font-size: 12px;

    color: rgba(255, 255, 255, 0.7);
    span {
      color: rgba(255, 255, 255, 1);
    }
    p {
      margin: 2px 0;
    }
    div {
      margin-bottom: 10px;
      &:nth-child(1) {
        margin-bottom: 15px;
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 10px;
    .name {
      display: flex;
      align-items: center;
      font-size: 20px;
    }
    img {
      width: 50px;
      margin-left: 5px;
    }
    .num {
      background: #3cdbe8;
      color: #2f293f;
      width: 85px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 2px;
    }
  }
  .cl-box {
    background: #1c172a;
    padding: 0 5px 10px 5px;
    margin-top: 18px;
    .title {
      text-align: center;
      font-size: 0px;
      padding: 5px 0;
      img {
        width: 237px;
        margin: 0 auto;
      }
    }
    .hero-wrapper {
      display: flex;
      justify-content: center;
    }
    .hero {
      display: flex;
      flex-wrap: wrap;
      font-size: 0px;
      //justify-content: space-between;
      img {
        width: 69px;
      }
      .mr10 {
        margin-right: 10px;
      }
      .choose {
        border: 1px solid #5fdfd3;
        border-radius: 4px;
        background-color: #2f293f;
      }
      .nochoose {
        border: 1px solid #1c172a;
        border-radius: 4px;
      }
      .hero-item {
        position: relative;
        margin-right: 10px;

        &:nth-child(3n + 4) {
          margin-right: 0px;
        }
        .box1 {
          // margin-right: 10px;
          // &:nth-child(3n + 4) {
          //   margin-right: 0px;
          // }
        }
        .num {
          position: absolute;
          right: 9px;
          top: 9px;
          background: #2f293f;
          font-size: 12px;
          width: 18px;
          height: 18px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          z-index: 2;
        }
        .neednum {
          font-size: 12px;
          text-align: center;
          span {
            color: $primary;
          }
        }
        .item-meng {
          width: 69px;
          height: 73px;
          background: #2f293f;
          opacity: 0.5;
          position: absolute;
          top: 0;
          border-radius: 50%;
        }
      }
    }
  }
}
</style>
